import React from "react";

import {
  Box,
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Image,
  Link,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  IconButton,
  VStack,
  Text,
  HStack,
} from "@chakra-ui/react";
import {
  ArrowRightIcon,
  ChevronDownIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";
import GuardhashLogo from "./assets/guardhash-logo.png";
import onClickContactUs from "utils/onClickContactUs";
import { useNavigate } from "react-router-dom";
import { AvailablePage } from "containers/Page/Page";

const Navbar: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  interface MenuItem {
    linkTitle: string;
    panelTitle: string;
    linksCount: number;
    rightPanelTitle: string;
    rightPanelDescription: string;
    leftItemContent: LeftItemContent[];
  }

  interface LeftItemContent {
    menuItemTitle: string;
    menuItemDescription: string;
    onClickMenuItem: () => void;
    onClickSecondMenuItem?: () => void;
  }

  const menuItems: MenuItem[] = [
    {
      linkTitle: "Use cases",
      panelTitle: "Use cases",
      linksCount: 4,
      rightPanelTitle: "Established Enterprise Solution",
      rightPanelDescription:
        "GuardHash stands out as a trusted enterprise solution designed to make data accessible even in the world's most intricate and strictly regulated settings. We pave the way for data-driven opportunities across diverse business landscapes and legal domains.",
      leftItemContent: [
        {
          menuItemTitle: "Marketing",
          menuItemDescription:
            "Retailers, distributors, and brands can jointly utilize their data, ensuring they cater to customer preferences in complete adherence to regulations.",
          onClickMenuItem: () => navigate(AvailablePage.Marketing),
          onClickSecondMenuItem: () => navigate(AvailablePage.MarketingUseCase),
        },
        {
          menuItemTitle: "Finance",
          menuItemDescription:
            "Financial institutions can now utilize private data across different regions and legal structures. This capability facilitates AI training, crafting of marketing approaches, refining risk assessments, and collaborating with fellow banks for more effective financial crime detection.          ",
          onClickMenuItem: () => navigate(AvailablePage.Finance),
          onClickSecondMenuItem: () => navigate(AvailablePage.FinanceUseCase),
        },
        {
          menuItemTitle: "Software engineering",
          menuItemDescription:
            "Utilize production data to refine and enhance your algorithms, free from concerns about traceability, data breaches, and regulatory compliance.",
          onClickMenuItem: () => navigate(AvailablePage.SoftwareEngineering),
          onClickSecondMenuItem: () => navigate(AvailablePage.SoftwareUseCase),
        },
        {
          menuItemTitle: "Healthcare",
          menuItemDescription:
            "Safeguard patient information while propelling medical studies forward using AI",
          onClickMenuItem: () => navigate(AvailablePage.HealthCare),
          onClickSecondMenuItem: () =>
            navigate(AvailablePage.HealthCareUseCase),
        },
      ],
    },
    {
      linkTitle: "Product",
      panelTitle: "Our tech",
      linksCount: 3,
      rightPanelTitle: "We do things better",
      rightPanelDescription:
        "Our product, leveraging the power of differential privacy, stands out as a superior solution compared to traditional anonymization, synthetic data, and data cleanroom techniques. While anonymization may leave room for re-identification, especially as computing power and methods advance, and synthetic data can occasionally stray from representing the true underlying distributions, differential privacy provides mathematical guarantees to protect individual data points without compromising the overall utility of the dataset. Furthermore, compared to data cleanrooms which only limit the scope of data access, our product, with its differential privacy mechanism, ensures that any output or analysis does not breach the privacy of individual entries. This ensures that our users can derive insights with confidence, knowing that they are working within a framework that prioritizes the utmost data privacy and integrity.",
      leftItemContent: [
        {
          menuItemTitle: "Remote execution",
          menuItemDescription:
            "Say goodbye to the risks associated with data transfer. With our remote execution tool, all computations take place within the data owner's architecture, ensuring maximum data protection.",
          onClickMenuItem: () => navigate(AvailablePage.Tech),
        },
        {
          menuItemTitle: "Differential Privacy",
          menuItemDescription:
            "We employ differential privacy by introducing smart noise to the data. While this maintains the data's statistical significance, it ensures individual data points remain indistinguishable and private.",

          onClickMenuItem: () => navigate(AvailablePage.Tech),
        },

        {
          menuItemTitle: "Synthetical data",
          menuItemDescription:
            "Before diving into the actual dataset, data scientists can explore and familiarize themselves with our high-quality synthetic data. This allows them to understand the dataset's structure without ever being exposed to the real data.",

          onClickMenuItem: () => navigate(AvailablePage.Tech),
        },
      ],
    },
    {
      linkTitle: "Customers",
      panelTitle: "Our clients range from finance to healthcare",
      linksCount: 3,
      rightPanelTitle: "Satisfaction guaranteed",
      rightPanelDescription:
        "When you choose our services, two things come as standard: unparalleled satisfaction and enterprise-level security. We stand firmly behind the quality and reliability of our offerings, ensuring that every client interaction results in absolute satisfaction—guaranteed. Alongside this commitment, we prioritize your data's safety with our advanced enterprise-level security protocols. By partnering with us, you're not only investing in top-tier solutions but also peace of mind, knowing your operations are supported by the best in the business.",
      leftItemContent: [
        {
          menuItemTitle: "Financial organizations",
          menuItemDescription:
            "Financial institutions often grapple with the intricate balance between accessing detailed data for analytics and risk assessments, while ensuring customer trust isn't compromised. The challenges include ensuring secure investments, guarding against fraudulent activities, and optimizing algorithmic trading.",
          onClickMenuItem: () => navigate(AvailablePage.FinanceUseCase),
        },
        {
          menuItemTitle: "Marketing teams",
          menuItemDescription:
            "In an era where personalization is key, marketers seek to understand consumer preferences without overstepping privacy boundaries. Tasks range from precise ad targeting without privacy invasion, collecting genuine survey feedback without personal data exposure, and monitoring social media trends without snooping.",
          onClickMenuItem: () => navigate(AvailablePage.MarketingUseCase),
        },
        {
          menuItemTitle: "Healthcare companies",
          menuItemDescription:
            "Healthcare demands a sacred trust in handling patient data. From ensuring secure access to patient records, conducting transparent clinical trials, to managing telemedicine data, the challenges are manifold. The sector also faces the need to advance genomic research without personal data disclosure and to streamline hospital admissions while safeguarding patient information.          ",
          onClickMenuItem: () => navigate(AvailablePage.HealthCareUseCase),
        },
        {
          menuItemTitle: "Software squads",
          menuItemDescription:
            "Software engineering involves myriad data interactions, be it for algorithm optimization, bug tracking, or feature testing. Engineers need to access and work on real-world data, but without compromising data integrity or security. This also extends to ensuring performance monitoring respects user privacy and that data migrations between systems are seamless and secure.",
          onClickMenuItem: () => navigate(AvailablePage.SoftwareUseCase),
        },
      ],
    },
  ];

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      padding="1rem"
      boxShadow="sm"
      bg="background.700"
    >
      <Image
        src={GuardhashLogo}
        alt="Logo"
        width="100px"
        _hover={{ cursor: "pointer" }}
        onClick={() => navigate(AvailablePage.Main)}
      />

      <Flex display={{ base: "none", md: "flex" }}>
        {menuItems.map((item, idx) => (
          <Menu key={idx}>
            {({ isOpen, onClose }) => (
              <>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  variant="ghost"
                  color="white" // Button text color set to light grey
                  _hover={{ bg: "barbie.400" }} // Hover color set to "action"
                  mx="2px"
                >
                  {item.linkTitle}
                </MenuButton>
                {isOpen && (
                  <MenuList
                    bg="background.700"
                    overflowX="hidden"
                    width="100vw"
                    boxSizing="border-box"
                  >
                    <Flex width="100%" height="80vh" pt="40px">
                      <Box width="50%" padding="1rem" px={["10px", "80px"]}>
                        <Text
                          fontSize="3xl"
                          color="action.400"
                          marginBottom="1rem"
                          fontWeight="bold"
                        >
                          {item.panelTitle}
                        </Text>
                        {item.leftItemContent.map((itemContent) => {
                          return (
                            <Box flex="1" bg="background.700" mb={2}>
                              <Flex
                                flexDirection="column"
                                justifyContent="space-between"
                                h="100%"
                                p={4} // Adding some padding
                              >
                                <VStack align="start" spacing={2}>
                                  <Text
                                    color="barbie.400"
                                    display="block"
                                    fontSize="xl"
                                    _hover={{
                                      cursor: "pointer",
                                      color: "barbie.600",
                                    }}
                                    onClick={() => {
                                      itemContent.onClickMenuItem();
                                      onClose();
                                    }}
                                  >
                                    {itemContent.menuItemTitle}
                                  </Text>
                                  <Text
                                    color="white"
                                    display="block"
                                    fontSize="lg"
                                  >
                                    {itemContent.menuItemDescription}
                                  </Text>
                                </VStack>
                                {
                                  <HStack
                                    spacing={4}
                                    mt={2}
                                    alignSelf="flex-end"
                                  >
                                    <Button
                                      variant="ghost"
                                      bg="action.400"
                                      color="white"
                                      size="xs"
                                      _hover={{ bg: "action.600" }}
                                      onClick={() => {
                                        itemContent.onClickMenuItem();
                                        onClose();
                                      }}
                                    >
                                      Find out more
                                      <ArrowRightIcon ml={2} />
                                    </Button>
                                    {itemContent.onClickSecondMenuItem && (
                                      <Button
                                        variant="ghost"
                                        bg="barbie.400"
                                        color="white"
                                        size="xs"
                                        _hover={{ bg: "barbie.600" }}
                                        onClick={() => {
                                          itemContent.onClickSecondMenuItem &&
                                            itemContent.onClickSecondMenuItem();
                                          onClose();
                                        }}
                                      >
                                        Success stories
                                        <ArrowRightIcon ml={2} />
                                      </Button>
                                    )}
                                  </HStack>
                                }
                              </Flex>
                            </Box>
                          );
                        })}
                      </Box>
                      {/* Right part with a title and description */}
                      <Box
                        bg="background.700"
                        padding="1rem"
                        width="50%"
                        px={["10px", "80px"]}
                      >
                        <Text
                          fontSize="2xl"
                          color="barbie.400"
                          marginBottom="1rem"
                          fontWeight="bold"
                        >
                          {item.rightPanelTitle}
                        </Text>
                        <Text fontSize="md" color="white">
                          {item.rightPanelDescription}
                        </Text>
                      </Box>
                    </Flex>
                  </MenuList>
                )}
              </>
            )}
          </Menu>
        ))}
      </Flex>

      <IconButton
        display={{ base: "flex", md: "none" }}
        aria-label="Open menu"
        variant="outline"
        onClick={onOpen}
        icon={<HamburgerIcon />}
        color="barbie.400"
        borderColor="barbie.400"
        size="lg" // Increase button size
      />

      <Button
        variant="ghost"
        bg="action.400"
        color="white"
        size="lg"
        _hover={{ bg: "action.600" }}
        onClick={onClickContactUs}
        display={{ base: "none", md: "block" }} // This line does the trick
      >
        Contact us
      </Button>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay>
          <DrawerContent maxW="100vw" backgroundColor="background.700">
            <DrawerHeader borderBottomWidth="1px" color="action.400">
              Navigation
            </DrawerHeader>
            <DrawerBody>
              <VStack spacing={4} align="start">
                {menuItems.map((item, idx) => (
                  <Box key={idx}>
                    <Text fontWeight="bold" mb={2} color="action.400">
                      {item.linkTitle}
                    </Text>
                    {item.leftItemContent.map((itemContent, index) => (
                      <Link
                        display="block"
                        mb={1}
                        color="barbie.400"
                        onClick={() => {
                          if (
                            typeof itemContent.onClickMenuItem === "function"
                          ) {
                            itemContent.onClickMenuItem();
                            onClose();
                          }
                        }}
                      >
                        {itemContent.menuItemTitle}
                      </Link>
                    ))}
                  </Box>
                ))}
                <Button
                  variant="ghost"
                  bg="action.400"
                  color="white"
                  _hover={{ bg: "action.600" }}
                  w="full"
                  onClick={onClickContactUs}
                >
                  Contact us
                </Button>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Flex>
  );
};

export default Navbar;
