import { Box, Flex, VStack, HStack, Text, Link, Image } from "@chakra-ui/react";
import React from "react";
import GuardhashLogo from "../../assets/gh-logo.png";
import LinkedinLogo from "./assets/linkedinLogo.png";
import SkyDeckLogo from "./assets/SkyDeckLogo.jpeg";
import EFlogo from "./assets/EFlogo.png";
import { useNavigate } from "react-router-dom";
import { AvailablePage } from "containers/Page/Page";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      bg="gray.800"
      color="white"
      width="100%"
      height={{ base: "auto", md: "450px" }}
      padding={6}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        align="center"
      >
        {/* First part (1/3 of space) */}
        <VStack flex={{ md: "1" }} spacing={2} align="flex-start">
          <Image src={GuardhashLogo} alt="Logo" width="100px" />
          <Text>EIN: 61-2095302</Text>

          <Text>2150 Shattuck Avenue</Text>
          <Text>Berkeley, California, 94704, USA</Text>
          <Link
            href="https://www.linkedin.com/company/guardhash"
            isExternal
            mt={2}
          >
            <Image src={LinkedinLogo} alt="LinkedIn" width="24px" />
          </Link>
        </VStack>

        {/* Second part (2/3 of space) */}
        <Flex
          flex={{ md: "2" }}
          direction={{ base: "column", md: "row" }}
          justify="space-around"
          align="flex-start"
        >
          {/* First Column */}
          <VStack spacing={4} align="flex-start">
            <Text fontWeight="bold">Use cases</Text>
            <Link onClick={() => navigate(AvailablePage.Marketing)}>
              Marketing
            </Link>
            <Link onClick={() => navigate(AvailablePage.Finance)}>Finance</Link>
            <Link onClick={() => navigate(AvailablePage.HealthCare)}>
              Healthcare
            </Link>
            <Link onClick={() => navigate(AvailablePage.SoftwareEngineering)}>
              Software Engineering
            </Link>
          </VStack>

          {/* Second Column */}
          <VStack spacing={4} align="flex-start">
            <Text fontWeight="bold">Our tech</Text>
            <Link onClick={() => navigate(AvailablePage.Tech)}>
              Find out more
            </Link>
          </VStack>
        </Flex>
      </Flex>
      <VStack justify="center" align="center" marginTop={10}>
        <Text fontSize="xl" fontWeight="bold">
          Backed by the best:
        </Text>
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="center"
          align="center"
        >
          <Image
            src={SkyDeckLogo}
            alt="SkyDeck"
            width="200px"
            marginBottom={{ base: "4", md: "0" }}
            paddingRight={[0, 4]}
          />
          <Image src={EFlogo} alt="EntrepreneurFirst" width="200px" />
        </Flex>
      </VStack>

      {/* Bottom copyright text */}
      <HStack justify="center" align="center" marginTop={10}>
        <Text fontSize="sm">
          ©{new Date().getFullYear()} GuardHash Inc. All rights reserved
        </Text>
      </HStack>
    </Box>
  );
};

export default Footer;
